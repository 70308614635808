// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/banners/edwica/edwica.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edwica-banner[data-v-e45fb7b6]{overflow:hidden;max-width:1280px;width:100%;border-radius:20px;background:linear-gradient(0deg,#ffdcea,#ffdcea),linear-gradient(114deg,#baffbb 15.51%,#fff1bf 84.49%)}.edwica-banner>.wrapper[data-v-e45fb7b6]{display:flex;flex-direction:column;height:275px;padding:36px 40px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:right 105px bottom;background-size:auto 97%}@media only screen and (max-width:1439px){.edwica-banner>.wrapper[data-v-e45fb7b6]{padding:28px 40px;background-size:225px auto;background-position:100% 100%}}@media only screen and (max-width:720px){.edwica-banner>.wrapper[data-v-e45fb7b6]{position:relative;height:350px;padding:22px 20px;background-size:253px auto;background-position:bottom}}.edwica-banner>.wrapper>.header>.title[data-v-e45fb7b6]{max-width:770px;margin-bottom:16px;font-size:32px;font-weight:500;line-height:40px;color:#101e2c}@media only screen and (max-width:1439px){.edwica-banner>.wrapper>.header>.title[data-v-e45fb7b6]{max-width:604px}}@media only screen and (max-width:720px){.edwica-banner>.wrapper>.header>.title[data-v-e45fb7b6]{font-size:18px;line-height:26px}}.edwica-banner>.wrapper>.button[data-v-e45fb7b6]{margin-top:auto;background:#966cee;transition:background-color .3s ease-in-out}.edwica-banner>.wrapper>.button[data-v-e45fb7b6]:hover{background-color:#865ce0}@media only screen and (max-width:720px){.edwica-banner>.wrapper>.button[data-v-e45fb7b6]{width:100%;height:48px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
