
import { defineComponent } from '@nuxtjs/composition-api'
import { SvoiButtonLink } from '@svoi-ui/components/button'

export default defineComponent({
  name: 'EdwicaBanner',
  components: {
    SvoiButtonLink
  }
})
